import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-6a.png';
import image2 from 'images/project-6b.png';
import image4 from 'images/project-6d.png';
import image5 from 'images/project-6e.png';
import image6 from 'images/project-6f.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Feels like a new home"
      meta={[
        {
          name: 'description',
          content:
            'Before Trevor&rsquo;s daughter Jane approached Ryhaven Trust, Trevor believed that the only option for his aging home was to bulldoze it.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Feels like a new home"
      intro="Before Trevor&rsquo;s daughter Jane approached Ryhaven Trust, Trevor believed that the only option for his aging home was to bulldoze it."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            He recalls sitting in his lounge looking at a house that was falling to bits, and asking
            himself, &lsquo;What can I do?&rsquo; His daughter Jane felt determined they would find
            a solution and after an initial discussion with the trust about what could be achieved,
            they found a pathway (which luckily), didn&rsquo;t involve bulldozing the house!
          </Paragraph>
          <Paragraph>
            Trevor bought his family home in 1987 whilst working for the railways. But when the roof
            started to leak and he didn&rsquo;t have any money to repair it, Trevor had no choice
            but to live with the problem. To add to this, several lights broke, the electrical
            system required improvement, and the house was cold and damp. &ldquo;If you
            haven&rsquo;t got the money, you just have to live with it, unless you win Lotto.&rdquo;
            Trevor says.
          </Paragraph>
          <Paragraph>
            After discussing what was urgently needed, the trust helped Trevor to arrange affordable
            finance and carry out a series of works. The former roof had major leaks which had lead
            to damage inside the house, so the trust added a new roof, fixed the electrics, then
            painted and remodelled the kitchen and bathroom.
          </Paragraph>
          <Paragraph>
            For Trevor the change was &ldquo;unbelievable&rdquo;. He describes his rejuvenated home,
            &ldquo;like a new house.&rdquo;
          </Paragraph>
          <Paragraph>
            With his family home restored it feels like a fresh beginning for Trevor and his
            children. &ldquo;Ryhaven Trust has got me to this point. I know people who have had work
            done and it has cost them a lot.&rdquo;
          </Paragraph>
          <Paragraph>
            Trevor&rsquo;s project was completed in partnership with local sponsors, Carters
            Building Supplies and Dulux (see our &ldquo;Dulux Adds some Shine to Taita Home&rsquo;
            story here). We thank our generous sponsors for their part in helping repair and restore
            homes in our community.
          </Paragraph>
          <Paragraph>
            Photography: Russell Finnerty
            <br />
            Words: Kelly McGuinness
            <br />
            2019
          </Paragraph>
          <img className={styles.image} src={image5} alt="" />
          <img className={styles.image} src={image6} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image4} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
